import './App.css';
import './style.css'
import Home from './views/home'
import React, { Component } from "react";

class App extends Component {
  render() {
    return (
      <Home />
    );
  }
}

export default App;
